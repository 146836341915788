.modal {
  width: 70vw;
}

.spinner {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.table {
  padding: 0 1rem;
}

.head {
  display: block;
}

.row {
  padding: 0 1.5rem;
  height: 5rem;
  display: flex;
  padding-bottom: 1rem;
}

.centered {
  text-align: center;
}

.hz {
  margin-left: 1.5rem;
  margin-top: -2rem;
}

.linkAll {
  padding: 0 1rem;
}

.bottom {
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.list {
  max-height: 40vh;
  overflow-y: auto;
}

.equalCheckbox {
    grid-column: span 3;
    justify-self: flex-end;
}
